.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #root {
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.specgpt-logo-title {
  font-size: 44px;
  font-weight: bold;
  letter-spacing: 0;
  color: #202A44;
}

.example-question-text {
  font-size: 16px;
  letter-spacing: 0%;
  text-align: center;  
}

.example-question-card {
  padding: 10%;
  border: 0px;
  border-radius: 20px;
  margin-top: 5%; 
  background-color: #8A8C8F; 
  color: #202A44;
}

.source-bubble {
  text-align: left;
}

.example-question-title {
  color: #202A44;
  font-size: 30px;
  font-weight: bold;
}
.clickable-example-question-title:hover {
  cursor: pointer;
  background-color: #D5E93E;
}

.beta-v1-text {
  color: #8A8C8F;
  font-size: 18px;
  text-align: center;
}

.color-text-gray {
  color: #8A8C8F;
}

.color-dark-blue {
  color: #202A44;
}

.color-light-yellow {
  color: #D5E93E;
}

.btn-yellow-blue {
  color: #D5E93E;
  background-color: #202A44;
}

.btn-white-blue {
  color: #FFFFFF;
  background-color: #202A44;
}

.btn-blue-yellow {
  color: #202A44;
  background-color: #D5E93E;
}

.btn-bold {
  font-weight: bold;
  --bs-btn-padding-y: 20px;
  --bs-btn-padding-x: 12px;
}

.bg-gray {
  background-color: #eaeaea;
}

.getting-started-step {
  color: #202A44;
  font-size: 24px;
  font-weight: bold;
  margin-top: 4%;
}

.getting-started-item {
  font-size: 18px;
}

.drag-drop-text {  
  font-size: 24px;
}